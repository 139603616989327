<template>
  <div class="boost-page">
    <div class="general-info">
      <div>
        <img src="/images/boost/boost-logo.svg" class="boost-logo"/>
        <div class="ri-bold-title">שיחה 1-על-1 עם מומחה</div>
        <div class="ri-title">מתחילים בבוסט!</div>
        <div class="blue-text">
          <span class="ri-bold-title">149 ₪</span>
          <span class="ri-title"> / בתשלום חד-פעמי</span>
        </div>
      </div>
      <img src="/images/boost/boost-description.svg" class="boost-description-image"/>
    </div>
    <div class="description">
      <div class="content ri-title">
        פגישת ייעוץ בשיחת זום אישית עם אחת או אחד מיועצי התזרים שלנו תאפשר לך להבין מהר את השימוש בתזרים ולהתאים אותו
        ליום יום שלך.
      </div>
    </div>
    <div class="images">
      <div class="square bubble">
        <div class="ri-label">
          המומחים והמומחיות שלנו כבר ייעצו לאלפי ישראלים וישראליות
        </div>
      </div>
      <div class="square pink dafi"></div>
      <div class="square blue sara"></div>
      <div class="square yellow shai"></div>
    </div>
    <div class="bullets">
      <bullet-list
        v-for="bullet in bullets"
        :key="bullet.title"
        :title="bullet.title"
        :text="bullet.text"
      />
    </div>
  </div>
</template>

<script>
import BulletList from './BulletList.vue';

export default {
  name: 'Boost',
  components: {
    BulletList,
  },
  data() {
    return {
      bullets: [
        {
          title: 'יד ביד',
          text: 'שיחה של שעה בזום 1-על-1 עם מומחית תזרים',
        },
        {
          title: 'כל מה שצריך לדעת ומהר',
          text: 'ללמוד את שיטת התזרים במהירות ואת כל היכולות שיש לשירות של רייזאפ להציע',
        },
        {
          title: 'במיוחד בשבילך',
          text: 'להתאים את התזרים למצב המשפחתי, לאורח החיים הייחודי שלך ובהתאם למטרות שלך!',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
@import '@riseupil/base-ui/src/scss/spacings';
@import '@riseupil/base-ui/src/scss/riseup-colors';
@import '@riseupil/base-ui/src/scss/border-radius';

.boost-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .general-info {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    justify-items: stretch;
    min-height: 247px;
    width: 100%;
    padding: $ri-spacing-l $ri-spacing-ml $ri-spacing-l 0;

    .boost-description-image {
      background-position: left;
      background-repeat: no-repeat;
    }

    .boost-logo {
      margin-bottom: $ri-spacing-sm;
    }

    .blue-text {
      color: $riseup_blue
    }
  }

  .description {
    background-color: $riseup_light_blue;
    color: $riseup_dark_blue;
    min-height: 258px;
    border-radius: $ri-border-radius-large $ri-border-radius-large 0 0;
    padding: 0 $ri-spacing-xl;

    .content {
      margin: $ri-spacing-xl 0;
    }
  }

  .images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 145px auto;
    align-items: end;
    gap: $ri-spacing-ml;

    .square {
      height: 204px;
      width: 156px;
      border-radius: $ri-border-radius-large;
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: contain;

      &.bubble {
        background-image: url(/images/boost/bubble.svg);
        background-position: bottom;
        background-repeat: no-repeat;
        padding: $ri-spacing-ml 0 $ri-spacing-m 0;
        display: flex;
        align-items: flex-end;

        div {
          margin: $ri-spacing-m $ri-spacing-sm;
          color: $riseup_dark_blue;
        }
      }

      &.pink {
        background-color: $riseup_light_red;
      }

      &.dafi {
        background-image: url('/images/boost/dafi.png');
      }

      &.yellow {
        background-color: $riseup_light_yellow;
      }

      &.sara {
        background-image: url('/images/boost/sara.png');
      }

      &.blue {
        background-color: $riseup_light_blue;
      }

      &.shai {
        background-image: url('/images/boost/shai.png');
      }
    }
  }

  .bullets {
    display: flex;
    flex-direction: column;
    gap: $ri-spacing-ml;
    padding: 0 $ri-spacing-l;
    margin: $ri-spacing-xl 0 $ri-spacing-xxl 0;
  }
}
</style>
