<template>
  <div class="switch-container">
    <div class="switch">
      <div class="switcher" :style="movingStyle"/>
      <div :class="['first-option', {selected: selectedOption === rightOption.id}]"
           @click="() => selectOption(rightOption.id)">
        <icon v-bind="rightOption.iconProps" :color="rightOptionColor"/>
        <span class="ri-large-bold-body">{{rightOption.text}}</span>
      </div>
      <div
        :class="['second-option', {selected: selectedOption === leftOption.id}]"
        @click="() => selectOption(leftOption.id)"
      >
        <icon v-bind="leftOption.iconProps" :color="leftOptionColor"/>
        <span class="ri-large-bold-body">{{leftOption.text}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'PillSwitch',
  components: {
    Icon: BaseUI.Icon,
  },
  props: {
    leftOption: {
      type: Object,
      validate: value => value.text && value.iconProps && value.id,
    },
    rightOption: {
      type: Object,
      validate: value => value.text && value.iconProps && value.id,
    },
  },
  data() {
    return {
      selectedOption: this.rightOption.id,
    };
  },
  computed: {
    leftOptionColor() {
      return this.selectedOption === this.leftOption.id ? BaseUI.Colors.riseupWhite : BaseUI.Colors.riseupBlue;
    },
    rightOptionColor() {
      return this.selectedOption === this.rightOption.id ? BaseUI.Colors.riseupWhite : BaseUI.Colors.riseupBlue;
    },
    movingStyle() {
      return this.selectedOption === this.leftOption.id ? { transform: 'translateX(-100%)' } : { transform: 'translateX(0%)' };
    },
  },
  methods: {
    selectOption(optionId) {
      this.selectedOption = optionId;
      this.$emit('selected', optionId);
    },
  },
};
</script>

<style scoped lang="scss">
@import '@riseupil/base-ui/src/scss/riseup-colors';
@import '@riseupil/base-ui/src/scss/spacings';
@import '@riseupil/base-ui/src/scss/border-radius';

$switch-padding: $ri-spacing-xxs;

.switch-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 72px;

  .switch {
    position: relative;
    background-color: $riseup_white;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'option-one option-two';
    // Not a proper border radius
    border-radius: 40px;
    color: $riseup_blue;
    padding: $switch-padding;
    height: 100%;
    width: 75%;
    border: 1px solid $riseup_light_blue;
    box-shadow: 0 4px 12px 0 $riseup_gray_4;

    *.selected {
      color: $riseup_white;
    }

    .first-option, .second-option {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: $ri-spacing-xs;
      // To always be on top of the switcher
      z-index: 3;

      ::v-deep span {
        // forcing the color transition on the icon (didn't work without the !important)
        transition: color 0.5s ease !important;
      }
    }

    .first-option {
      grid-area: option-one;
    }

    .second-option {
      grid-area: option-two;
    }

    .switcher {
      width: 100%;
      height: 100%;
      background-color: $riseup_blue;
      // Not a proper border radius
      border-radius: 40px;
      transition: transform 0.3s ease;
      grid-area: option-one;

      &.move-right {
        transition: none;
      }

      &.move-left {
        transform: translateX(-100%);
      }
    }
  }
}
</style>
