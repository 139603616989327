<template>
  <div class="onit-page">
    <div class="general-info">
        <img src="/images/im-on-it/onit-general-info.svg" class="general-info-background" alt="general info section background">
        <img src="/images/im-on-it/onit-green-logo.svg" class="onit-logo" alt="on-it program logo"/>
        <div class="texts">
          <span class="ri-bold-title">תוכנית האימון הקבוצתית</span>
          <span class="ri-title">שמאפשרת לקחת שליטה על הכסף</span>
          <span class="ri-bold-title">ב-2 דקות</span>
        </div>
        <div class="blue-text">
          <span class="ri-bold-title">99 ₪</span>
          <span class="ri-title"> / בתשלום חד-פעמי</span>
        </div>
    </div>
    <div class="description">
      <img src="/images/im-on-it/onit-description.svg" class="description-image" alt="green flower background">
      <div class="content ri-title">
        <span>
        שיטה פשוטה מבוססת מחקר לאימון בקבוצה שאפשרת לך, בצעדים קטנים, לפתח הרגלים מנצחים ולקחת את הכסף בידיים.
        </span>
        <span class="ri-bold-title">
           והכל ב-2 דקות ביום.
        </span>
      </div>
    </div>
    <div class="bullets">
      <bullet-list
        v-for="bullet in bullets"
        :key="bullet.title"
        :title="bullet.title"
        :text="bullet.text"
        color-variant="green"
      />
    </div>
    <div class="testimony box-shadow-medium">
      <img src="/images/im-on-it/onit-testimony.png" alt="testimony" />
      <div class="quote ri-body">
        <span class="ri-medium-bold-display ri-alte">“</span>
        <span>
          זה שינה לי לגמרי דפוסים של שנים של להוציא כל מה שאני מכניסה או להגיע לגרעון בסוף החודש או
          להתקע בלי כסף כשיש בלת"ם. בקיצור ממש שמחה שנרשמתי לרייזאפ ממש שמחה על קבוצת על זה ביחד. וממש שמחה על השינוי
        </span>
        <div class="signature">
          <span>קרן</span>
          <span class="ri-bold-body">מחזור אפריל 2023</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BulletList from './BulletList.vue';

export default {
  name: 'Boost',
  components: {
    BulletList,
  },
  data() {
    return {
      bullets: [
        {
          title: 'נוסחת הצלחה יומית',
          text: 'כלי מעקב ייחודי למשתתפי התוכנית, ישירות מתוך מערכת רייזאפ, בהשקעה של 2 דקות ביום',
        },
        {
          title: '4 שבועות של תוכן',
          text: 'סרטונים, מידע, כלים וטיפים בהנחיית מומחים שיעזרו לך לקחת את הכסף בידיים',
        },
        {
          title: 'אתגר שבועי',
          text: 'בכל שבוע נערוך אתגרים לבניית הרגלים הדרגתית',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
@import '@riseupil/base-ui/src/scss/spacings';
@import '@riseupil/base-ui/src/scss/riseup-colors';
@import '@riseupil/base-ui/src/scss/border-radius';
@import '@riseupil/base-ui/src/scss/box-shadow';

.onit-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .general-info {
    position: relative;
    min-height: 247px;
    width: 100%;
    padding: $ri-spacing-ml;

    .general-info-background {
      position: absolute;
      // To cover the gap
      top: -42px;
      left: 0;
      width: 50%;
    }

    .boost-description-image {
      background-position: left;
      background-repeat: no-repeat;
    }

    .onit-logo {
      margin-bottom: $ri-spacing-sm;
    }

    .blue-text {
      color: $riseup_blue
    }
  }

  .description {
    position: relative;
    background-color: $riseup_light_green;
    color: $riseup_dark_green;
    min-height: 258px;
    border-radius: $ri-border-radius-large $ri-border-radius-large 0 0;
    padding: 0 $ri-spacing-xl;

    .description-image {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 58%;
    }

    .content {
      // setting position to relative to show the text above
      // the image (that is positioned absolute)
      position: relative;
      margin: $ri-spacing-xl 0;
    }
  }

  .bullets {
    display: flex;
    flex-direction: column;
    gap: $ri-spacing-ml;
    padding: 0 $ri-spacing-l;
    margin: $ri-spacing-xl 0 $ri-spacing-xxl 0;
  }

  .testimony {
    margin: 0 $ri-spacing-xxl $ri-spacing-l $ri-spacing-xxl;
    border-radius: 40px;
    background-color: $riseup_dark_green;
    color: $riseup_white;

    img {
      width: 100%;
      // Needs to be less than the border-radius of the testimony
      border-radius: 36px;
    }

    .quote {
      padding: $ri-spacing-ml;
      display: flex;
      flex-direction: column;
      gap: $ri-spacing-ml;

      .signature {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
</style>
