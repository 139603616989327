<template>
  <sticky-cta-page :cta-props="ctaProps" :page-background-color="pageBackgroundColor" @click="continueToOffering">
    <image-header-page :image-header-props="imageHeaderProps" class="page" >
      <template v-slot:header>
        <div class="texts">
          <div class="ri-large-body">לשנות הרגלים ביחד</div>
          <div class="ri-large-headline">אפשר להציע לך תוכנית ליווי שתעזור לך לצאת לדרך?</div>
        </div>
      </template>
      <template v-slot:overlayContent>
        <pill-switch v-bind="groupSwitchProps" @selected="selectOffer"/>
      </template>
      <Boost v-if="isBoostSelected"/>
      <OnIt v-else-if="isThirtyDaysSelected"/>
    </image-header-page>
  </sticky-cta-page>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Colors from '@riseupil/base-ui/src/scss/riseup-js-colors.module.scss';
import { mapActions } from 'vuex';
import { COLOR_VARIANT } from '@riseupil/base-ui/src/utils/layout-consts';
import PillSwitch from '@/base-components/PillSwitch.vue';
import { SIGNUP_OFFERING_IDS } from '@/store/modules/signup-offerings/oneOff-Offerings';
import Segment from '@/Segment';
import Boost from './Boost.vue';
import OnIt from './OnIt.vue';

export default {
  name: 'SignupOfferingsV2',
  components: {
    PillSwitch,
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
    ImageHeaderPage: BaseUI.Pages.ImageHeaderPage,
    Boost,
    OnIt,
  },
  data() {
    return {
      imageHeaderProps: {
        title: '',
        backgroundColor: COLOR_VARIANT.LIGHT_BLUE,
        imgHeight: 212,
        textColor: Colors.riseupDarkBlue,
        bottomSeparator: false,
      },
      groupSwitchProps: {
        leftOption: {
          text: 'בקבוצה',
          iconProps: {
            iconName: 'groups_2',
            fill: true,
            size: '24px',
          },
          id: SIGNUP_OFFERING_IDS.THIRTY_DAYS,
        },
        rightOption: {
          text: '1 על 1',
          iconProps: {
            iconName: 'person_raised_hand',
            fill: true,
            size: '20px',
          },
          id: SIGNUP_OFFERING_IDS.BOOST,
        },
      },
      ctaProps: {
        backgroundColor: Colors.riseupBeige,
        buttonProps: {
          title: 'להצטרף לתוכנית',
        },
      },
      pageBackgroundColor: Colors.riseupBeige,
      selectedOffer: SIGNUP_OFFERING_IDS.BOOST,
    };
  },
  created() {
    // Initialize signup offerings for when selecting an offer
    this.init({
      offerIds: [SIGNUP_OFFERING_IDS.BOOST, SIGNUP_OFFERING_IDS.THIRTY_DAYS],
      featureFlagAvailableOfferings: [],
    });
    this.setAppHeaderProps({
      backgroundColorVariant: COLOR_VARIANT.LIGHT_BLUE,
      textColor: Colors.riseupDarkGreen,
      logoColor: Colors.riseupDarkGreen,
      sticky: true,
      showLogo: true,
      bottomBorder: true,
      rightActions: [{
        type: BaseUI.HeaderActions.SKIP,
        onClick: this.returnToHome,
      }],
    });
    Segment.trackUserInteraction('OfferingsPageV2_Shown');
  },
  computed: {
    isBoostSelected() {
      return this.selectedOffer === SIGNUP_OFFERING_IDS.BOOST;
    },
    isThirtyDaysSelected() {
      return this.selectedOffer === SIGNUP_OFFERING_IDS.THIRTY_DAYS;
    },
  },
  methods: {
    ...mapActions('signupOfferings', ['init']),
    ...mapActions('appHeader', ['setAppHeaderProps']),
    selectOffer(offerId) {
      this.selectedOffer = offerId;
      Segment.trackUserInteraction('SwitchOffers', { movedTo: offerId });
    },
    returnToHome() {
      this.$router.push({ path: '/home' });
    },
    continueToOffering() {
      this.$router.push({ path: `/offerings/${this.selectedOffer}` });
    },
  },
};
</script>

<style scoped lang="scss">
@import '@riseupil/base-ui/src/scss/riseup-colors';
@import '@riseupil/base-ui/src/scss/spacings';

.page {
  background-color: $riseup_beige;

  .texts {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: $ri-spacing-xs;
    color: $riseup_dark_blue;
  }
}
</style>
