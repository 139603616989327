import { render, staticRenderFns } from "./PillSwitch.vue?vue&type=template&id=0615879f&scoped=true&"
import script from "./PillSwitch.vue?vue&type=script&lang=js&"
export * from "./PillSwitch.vue?vue&type=script&lang=js&"
import style0 from "./PillSwitch.vue?vue&type=style&index=0&id=0615879f&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0615879f",
  null
  
)

export default component.exports