<template>
  <div class="info-row">
    <div class="bullet">
      <colored-svg class="flower" :color="flowerColor"  svg-src="/images/shapes/eight-leaves-flower.svg" />
      <icon class="check" icon-name="check" size="20px" weight="700" :color="checkColor" fill />
    </div>
    <div class="display-flex flex-column">
      <span class="ri-bold-body">{{title}}</span>
      <span class="ri-body">{{text}}</span>
    </div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'OfferingDetailsBullet',
  components: {
    coloredSvg: BaseUI.ColoredSvg,
    Icon: BaseUI.Icon,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    colorVariant: {
      type: String,
      default: 'blue',
      validate: val => ['blue', 'green'].includes(val),
    },
  },
  computed: {
    flowerColor() {
      switch (this.colorVariant) {
      case 'green':
        return BaseUI.Colors.riseupLightGreen;
      case 'blue':
      default:
        return BaseUI.Colors.riseupLightBlue;
      }
    },
    checkColor() {
      switch (this.colorVariant) {
      case 'green':
        return BaseUI.Colors.riseupDarkGreen;
      case 'blue':
      default:
        return BaseUI.Colors.riseupDarkBlue;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@riseupil/base-ui/src/scss/riseup-colors';
@import '@riseupil/base-ui/src/scss/spacings';
.info-row {
  display: flex;
  align-items: flex-start;
  width: 100%;
  color: $riseup_gray_100;
  gap: $ri-spacing-ml;

  .bullet {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .flower {
      $flowerWidth: 42px;
      width: $flowerWidth;
      height: $flowerWidth;
    }

    .check {
      $checkWidth: 20px;
      position: absolute;
      top: calc(50% - #{$checkWidth / 2});
      bottom: calc(50% - #{$checkWidth / 2});
      width: $checkWidth;
      height: $checkWidth;

    }
  }

}
</style>
